import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { setWalkPoint } from "../lib/setStages";

export default function WalkStageEditDialog(props) {
  const { open, place, walkid, onClose } = props;
  let StageDesc;
  let InterstitialDesc;
  const handleSave = () => {
    setWalkPoint(walkid, place, StageDesc, InterstitialDesc);
    handleClose();
  };
  const handleClose = () => {
    onClose(true);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Walk Stage</DialogTitle>
        <DialogContent>
          <DialogContentText>Stage: {place.title}.</DialogContentText>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <TextField
                  id="stage-description"
                  label="Stage Description"
                  fullWidth
                  variant="standard"
                  multiline
                  defaultValue={StageDesc}
                  onChange={(event) => {
                    StageDesc = event.target.value;
                  }}
                />
              </Grid>
              <Grid item xs={12} sm container>
                <TextField
                  id="interstitial-description"
                  label="Interstitial Description"
                  variant="standard"
                  defaultValue={InterstitialDesc}
                  multiline
                  onChange={(event) => {
                    InterstitialDesc = event.target.value;
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

WalkStageEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
  place: PropTypes.string,
  walkid: PropTypes.string,
  onClose: PropTypes.func,
};
