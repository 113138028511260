import { BASE_URL } from "./url";
import { getToken } from "../lib/authentication";

const getWalks = async () => {
  let url = `${BASE_URL}/walks`;

  let response = await fetch(url);

  if (response.ok) {
    // HTTP status is 200-299
    let json = await response.json();
    return json;
  } else {
    console.error(`HTTP Error: ${response.status}`);
    return {
      status: 400,
      query: {
        walks: [],
      },
    };
  }
};

export async function getMyWalks() {
  const url = `${BASE_URL}/editors/walks`;
  const requestOptions = {
    headers: { Authorization: getToken() },
  };

  const response = await fetch(url, requestOptions);

  if (response.ok) {
    // HTTP status is 200-299
    return await response.json();
  } else {
    console.error(`HTTP Error: ${response.status}`);
    return {
      status: 400,
      query: {
        walks: [],
      },
    };
  }
}

export const AddWalk = async (walk) => {
  let walkID = 0;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: getToken() },
    body: JSON.stringify(walk),
  };
  fetch(`${BASE_URL}/editors/walks`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
    });

  return walkID;
};
export default getWalks;
