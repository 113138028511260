import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { AddWalk } from "../lib/getWalks";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import PropTypes from "prop-types";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import WalkNewNameDialog from "./WalkNewName";
import { blue } from "@mui/material/colors";
import { getMyWalks } from "../lib/getWalks";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function WalksListAdminView(props) {
  const [availableWalks, setAvailableWalks] = useState([]);
  const [selectedWalk, setSelectedWalk] = useState([]);
  // TODO: Migrate to react-hook-form
  const [newWalkName, setNewWalkName] = useState("");
  const [newWalkShortDesc, setNewWalkShortDesc] = useState("");
  const [newWalkLongDesc, setNewWalkLongDesc] = useState("");
  const { onClose, open, handleWalksListAdminView, setViewWalksListAdminOpen } =
    props;
  useEffect(() => {
    let ignore = false;
    setAvailableWalks(null);
    getMyWalks().then((result) => {
      if (!ignore) {
        setAvailableWalks(result);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);

  const classes = useStyles();
  const [isDirty, setIsDirty] = React.useState(false);
  const [nameWalkView, setNameWalkView] = React.useState(false);

  const handleWalksListClose = () => {
    if (isDirty) {
      var newWalk = availableWalks?.filter(function (newWalkAdder) {
        return newWalkAdder.id === "";
      });
      const CreatenewWalk = {
        name: newWalk ? newWalk[0].name : newWalkName,
        shortdescription: newWalkShortDesc,
        longdescription: newWalkLongDesc,
      };

      AddWalk(CreatenewWalk);
    }
    onClose(selectedWalk);
  };
  const handleClickAddWalk = () => {
    onClose();
    setNameWalkView(true);
  };
  const handleAddWalk = (walkname) => {
    const newWalk = {
      id: "",
      name: walkname,
      steps: 0,
      owner: "",
      short_description: "",
      long_description: "",
    };
    if (availableWalks) {
      setAvailableWalks((old) => [...old, newWalk]);
    }
    setNewWalkName(walkname);
    setIsDirty(true);
    console.log(walkname);
  };
  const handleListItemClick = (value) => {
    availableWalks?.forEach((walk) => {
      if (walk.id === value) setSelectedWalk(walk);
    });
  };
  const handleNewNameClose = () => {
    setNameWalkView(false);
    onClose();
  };

  function WalkLists() {
    if (availableWalks) {
      return availableWalks.map((walk) => (
        <ListItem
          button
          onClick={() => handleListItemClick(walk.id)}
          key={walk.id}
          selected={walk.id === ""}
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={walk.name} />
        </ListItem>
      ));
    }
  }

  return (
    <div>
      <Dialog
        onClose={handleWalksListClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">My Walks</DialogTitle>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <List>
                <WalkLists />
              </List>
              <Grid item xs={12} sm container>
                <IconButton
                  variant="outlined"
                  onClick={handleClickAddWalk}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
                <IconButton variant="contained" size="large">
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sm container>
              <TextField
                required
                id="filled-required"
                label="Walk"
                value={selectedWalk?.short_description}
                variant="standard"
                onChange={(e) => {
                  setNewWalkShortDesc(e.target.value);
                }}
              />

              <TextField
                required
                id="standard-required"
                label="Description"
                variant="standard"
                multiline
                value={selectedWalk?.long_description}
                onChange={(e) => {
                  setNewWalkLongDesc(e.target.value);
                }}
              />

              <TextField
                id="standard-read-only-input"
                label="Steps"
                defaultValue="0"
                InputProps={{
                  readOnly: true,
                }}
                value={selectedWalk?.steps}
                variant="standard"
              />

              <div></div>

              <Button
                variant="contained"
                onClick={() => handleWalksListClose(selectedWalk)}
              >
                {isDirty ? "Save Walk" : "Choose Walk"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <WalkNewNameDialog
        openWalkName={nameWalkView}
        onClose={handleNewNameClose}
        setAddWalk={handleAddWalk}
        WalksListAdminView={handleWalksListAdminView}
        setViewWalksListAdminOpen={setViewWalksListAdminOpen}
      ></WalkNewNameDialog>
    </div>
  );
}

WalksListAdminView.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleWalksListAdminView: PropTypes.func.isRequired,
  setViewWalksListAdminOpen: PropTypes.func.isRequired,
};

export default WalksListAdminView;
