import { BASE_URL } from "./url";

const getAPILayers = async () => {
  let url = `${BASE_URL}/sources`;

  let response = await fetch(url);

  if (response.ok) {
    // HTTP status is 200-299
    let json = await response.json();
    return json;
  } else {
    console.error(`HTTP Error: ${response.status}`);
    return {
      status: 400,
      query: {},
    };
  }
};

export default getAPILayers;
