import { BASE_URL } from "./url";

const getNearby = async (lat, lng, range, sources) => {
  let qs = "";
  for (let j = 0; j < sources.length; j++) {
    qs += '&source=' + sources[j]; 
  }
//    const qs = '&sources=' + encodeURIComponent(JSON.stringify(sources))
  let url = `${BASE_URL}/nearby?lat=${lat}&lng=${lng}&range=${range}${qs}`;
  let response = await fetch(url);

  if (response.ok) {
    // HTTP status is 200-299
    let json = await response.json();
    return json;
  } else {
    console.error(`HTTP Error: ${response.status}`);
    return {
      status: 400,
      query: {
        pages: [],
      },
    };
  }
};

export default getNearby;
