import "./App.css";

import { Button, Popover } from "@material-ui/core";
import {
  CircleMenu,
  CircleMenuItem,
  TooltipPlacement,
} from "react-circular-menu";
import React, { useEffect, useRef, useState } from "react";
import {
  getLayers,
  isWalkSelected,
  setLayers,
  setSelectedLayer,
  setSelectedWalkPrf,
} from "./lib/preferences";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import Checkbox from "@mui/material/Checkbox";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InfoView from "./InfoView";
import LayersIcon from "@mui/icons-material/Layers";
// Layers
import LayersView from "./layers/layersView";
import { Link } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Person";
// Login
import LoginView from "./login/LoginView";
import Map from "./Map";
import RegisterView from "./register/RegisterView";
import SearchIcon from "@mui/icons-material/Search";
// Search
import SearchView from "./search/Search";
import SettingsView from "./settings/Settings";
import Toolbar from "@mui/material/Toolbar";
import TuneIcon from "@mui/icons-material/Tune";
import Typography from "@mui/material/Typography";
import WalkInfoDrawer from "./components/walkinfo/walkinfo";
import WalksListAdminView from "./walks/walksListAdminView";
//Walks
import WalksListView from "./walks/walksListView";
import fetchDelayUtil from "./_utils/fetchDelayUtil";
import fetchGPSPermissionUtil from "./_utils/fetchGPSPermissionUtil";
import fetchRangeUtil from "./_utils/fetchRangeUtil";
import { getMyWalks } from "./lib/getWalks";
import getWalk from "./lib/getWalk";
import { isUserLoggedIn, logout } from "./lib/authentication";
import { useLoadScript } from "@react-google-maps/api";

const libs = ["places", "marker"];
const googleApiKey = "AIzaSyBSMA9xJI78iGq7Q8ZfNJBosQhQdqRNLfM";

const App = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleApiKey,
    libraries: libs,
    channel: "beta",
  });
  const myRef = useRef();
  // Walks
  const [selectedWalk, setSelectedWalk] = React.useState([]);
  const [viewWalksListOpen, setViewWalksListOpen] = React.useState(false);
  const [viewWalksListAdminOpen, setViewWalksListAdminOpen] =
    React.useState(false);
  //const [currentWalk, setCurrentWalk] = React.useState({})
  const handleWalksListClose = (userSelectedWalk) => {
    setViewWalksListOpen(false);
    //    setselectedWalk(value);
    //    setSelectedWalkPrf(value)

    //    if (userSelectedWalk) {
    if (userSelectedWalk > 0) {
      loadWalkDetails(userSelectedWalk);
      setWalkInfoOpen(true);
    } else {
      setWalkInfoOpen(false);
    }

    //    }
  };

  const loadWalkDetails = async (walkid) => {
    await getWalk(walkid).then((walkDetails) => {
      setSelectedWalk(walkDetails);
    });
  };

  const handleWalksListAdminViewClose = () => {
    setViewWalksListAdminOpen(false);
  };
  // Login
  const [viewLoginOpen, setViewLoginOpen] = React.useState(false);
  const [viewRegisterOpen, setViewRegisterOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [walkInfoOpen, setWalkInfoOpen] = React.useState(false);

  useEffect(() => {
    setViewInfoOpen(false);
    setViewSettingsOpen(false);
    setLayers();
  }, []);
  // useEffect(() => {
  //   if (sources) {
  //     setLayers(sources)

  //   }
  // }, [sources])
  // useEffect(() => {
  //   let ignore = false;
  //   if (selectedWalk?.id !== undefined) {
  //     if (currentWalk.length ===0){
  //       getWalk(selectedWalk.id).then(result => {
  //        if (!ignore) {
  //          setCurrentWalk(result);
  //         }
  //       });
  //     }
  //     return () => {
  //       ignore = true;
  //     };
  //   }
  // }, [selectedWalk])
  useEffect(() => {
    if (selectedWalk && selectedWalk?.points?.length > 0) {
      setWalkInfoOpen(true);
      myRef.current?.renderWalk(selectedWalk);
    }
  }, [selectedWalk]);
  const toronto = {
    // Guildhall Yard
    lat: 43.716634,
    lng: -79.400041,
  };
  const delay = fetchDelayUtil.get();
  const range = fetchRangeUtil.get();
  const gpsPermission = fetchGPSPermissionUtil.get();

  const [apiLoaded, setApiLoaded] = useState(false);
  const [fetchDelay, setFetchDelay] = useState(delay); // in seconds
  const [fetchRange, setFetchRange] = useState(range); // in seconds
  const [geo, allowGeo] = useState(false);
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [lastFetch, setLastFetch] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(true);
  const [place, setPlace] = useState(null);
  const [places, setPlaces] = useState([]);
  const [userHasPanned, setUserHasPanned] = useState(true);
  const [viewInfoOpen, setViewInfoOpen] = useState(false);
  const [viewSettingsOpen, setViewSettingsOpen] = useState(false);
  const [map] = useState(null);
  const [selected, setSelected] = useState({ lat: 0, lng: 0 });
  const [myWalks, setMyWalks] = useState([]);
  const [position, setPosition] = useState("bottom");
  const handleTitleClick = () => {
    setViewInfoOpen(false);
    setViewSearchOpen(false);
    setPlace(null);
  };
  const handleSelectLayersOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickAwayFromLayers = () => {
    setAnchorEl(null);
  };
  const handleCenterClick = (evt) => {
    evt.preventDefault();
    setUserHasPanned(false);
    setLastFetch(0);
  };

  const handleWalkEditClick = () => {
    setViewWalksListAdminOpen(true);
  };
  const handleLoginClick = () => {
    setViewLoginOpen(true);
  };

  const handleLoginClose = () => {
    setViewLoginOpen(false);
  };
  const fullscreen = isFullscreen ? "fullscreen" : "";

  //Search
  const [viewSearchOpen, setViewSearchOpen] = useState(false);
  const handleSearchClose = (value) => {
    setViewSearchOpen(false);
    setSelected(value);
    myRef.current.panToCenterReset(value);

    //TODO Pan to value
  };
  const handleSearchAbandon = (value) => {
    setViewSearchOpen(false);
  };
  /* const centerOnMeClasses = geo && userHasPanned ?
    'center-on-me active'
    : 'center-on-me';
 */
  //window.addEventListener("deviceorientation", handleOrientation, false);

  if (!isLoaded) return <div>Loading...</div>;
  let ActionButton;
  if (isUserLoggedIn()) {
    ActionButton = (
      <div style={{ display: "flex", gap: ".5rem" }}>
        <Link onClick={handleWalkEditClick}>
          <EditIcon sx={{ color: "#ff334b", fontSize: 40 }} />
        </Link>
        <Link onClick={logout}>
          <LogoutIcon sx={{ color: "#c23444", fontSize: 40 }} />
        </Link>
      </div>
    );
    if (myWalks && myWalks.length === 0) {
      getMyWalks().then((walks) => setMyWalks(walks));
    }
  } else {
    ActionButton = (
      <Link onClick={handleLoginClick}>
        <LoginIcon sx={{ color: "#ff334b", fontSize: 40 }} />
      </Link>
    );
  }
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ bgcolor: "#351353" }}>
          <Toolbar>
            <CircleMenu
              startAngle={13}
              rotationAngle={100}
              itemSize={1.5}
              radius={17}
              className="radial-menu"
              rotationAngleInclusive={false}
            >
              <CircleMenuItem
                tooltip="Settings"
                onClick={() => setViewSettingsOpen(true)}
                style={{ background: "#351353", color: "red" }}
              >
                <TuneIcon />
              </CircleMenuItem>

              <CircleMenuItem
                tooltip="Center"
                onClick={(evt) => handleCenterClick(evt)}
                className="center-on-me"
                style={{ background: "#351353", color: "red" }}
              >
                <CenterFocusWeakIcon />
              </CircleMenuItem>

              <CircleMenuItem
                tooltip="Layers"
                onClick={handleSelectLayersOpen}
                style={{ background: "#351353", color: "red" }}
              >
                <LayersIcon />
              </CircleMenuItem>

              <CircleMenuItem
                tooltip="Walk"
                onClick={() => setViewWalksListOpen(true)}
                style={{ background: "#351353", color: "red" }}
              >
                <DirectionsWalkIcon />
              </CircleMenuItem>

              <CircleMenuItem
                tooltip="Search"
                tooltipPlacement={TooltipPlacement.Bottom}
                style={{ background: "#351353", color: "red" }}
                onClick={() => setViewSearchOpen(true)}
              >
                <SearchIcon />
              </CircleMenuItem>
            </CircleMenu>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center", m: 1 }}
            >
              VeraCity.London
            </Typography>
            {ActionButton}
          </Toolbar>
        </AppBar>
      </Box>
      <Box>
        <main className="App-map">
          <Map
            ref={myRef}
            apiLoaded={apiLoaded}
            fetchDelay={fetchDelay}
            fetchRange={fetchRange}
            geo={geo}
            isFirstFetch={isFirstFetch}
            lastFetch={lastFetch}
            setApiLoaded={setApiLoaded}
            places={places}
            setIsFirstFetch={setIsFirstFetch}
            setLastFetch={setLastFetch}
            setPlace={setPlace}
            setPlaces={setPlaces}
            setUserHasPanned={setUserHasPanned}
            userHasPanned={userHasPanned}
            setViewInfoOpen={setViewInfoOpen}
            setViewSearchOpen={setViewSearchOpen}
            map={map}
          />
        </main>
      </Box>
      <InfoView
        open={viewInfoOpen}
        onClose={() => setViewInfoOpen(false)}
        geo={geo}
        place={place}
        allowGeo={allowGeo}
        setIsFullscreen={setIsFullscreen}
        setViewInfoOpen={setViewInfoOpen}
        myWalks={myWalks}
      />
      <SettingsView
        open={viewSettingsOpen}
        fetchDelay={fetchDelay}
        setFetchDelay={setFetchDelay}
        fetchRange={fetchRange}
        setFetchRange={setFetchRange}
        allowGeo={allowGeo}
        onClose={() => setViewSettingsOpen(false)}
      />
      <SearchView
        openSearch={viewSearchOpen}
        onClose={handleSearchAbandon}
        handleSearchClose={handleSearchClose}
        selected={selected}
        setSelected={setSelected}
      />
      <WalksListView open={viewWalksListOpen} onClose={handleWalksListClose} />
      <LoginView
        open={viewLoginOpen}
        onClose={handleLoginClose}
        setViewRegisterOpen={setViewRegisterOpen}
        setViewLoginOpen={setViewLoginOpen}
        setMyWalks={setMyWalks}
      />
      <RegisterView
        open={viewRegisterOpen}
        setViewRegisterOpen={setViewRegisterOpen}
      />
      <WalksListAdminView
        open={viewWalksListAdminOpen}
        onClose={handleWalksListAdminViewClose}
        setViewWalksListAdminOpen={setViewWalksListAdminOpen}
      />
      <LayersView
        anchorEl={anchorEl}
        setSelectedLayer={setSelectedLayer}
        handleClickAwayFromLayers={handleClickAwayFromLayers}
      ></LayersView>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ m: 0.5 }}>
          <FormGroup>
            {getLayers()?.map((source) => (
              <FormControlLabel
                key={source.id}
                control={
                  <Checkbox
                    id={source.id}
                    checked={source.selected}
                    onChange={(e) => {
                      setSelectedLayer(parseInt(e.target.id), e.target.checked);
                    }}
                  />
                }
                label={source.name}
              />
            ))}
            <Button onClick={() => handleClickAwayFromLayers()}>Close</Button>
          </FormGroup>
        </Box>
      </Popover>
      <WalkInfoDrawer
        WalkInfoDrawerOpen={walkInfoOpen}
        setWalkInfoOpen={setWalkInfoOpen}
        position={position}
        handleSearchClose={handleSearchClose}
      />
    </div>
  );
};

export default App;
