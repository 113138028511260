const fetchRangeUtil = {
  get: () => {
    // get fetchRange value from localStorage, if one has been saved
    let value = localStorage.getItem("FETCH_RANGE");

    // keep these values in sync with Settings
    if (null === value) {
      value = 300; // larger numbers make less frequent requests, setting this to 0 gives a better first-run experience
    } else {
      // values in localStorage are strings, so cast as a number
      value = +value;
      if (value < 20) {
        // Smallest logical range
        value = 20;
      }
    }

    return value;
  },
  set: (value) => {
    localStorage.setItem("FETCH_RANGE", value);
  },
};

export default fetchRangeUtil;
