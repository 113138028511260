import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MaterialSwitch from "react-switch";
import ReactSlider from "react-slider";
import fetchDelayUtil from "../_utils/fetchDelayUtil";
import fetchGPSPermissionUtil from "../_utils/fetchGPSPermissionUtil";
import React from "react";
import PropTypes from "prop-types";

const SettingsView = (props) => {
  const {
    fetchDelay,
    setFetchDelay,
    fetchRange,
    setFetchRange,
    allowGeo,
    onClose,
    open,
  } = props;

  const [delayMin, delayMax, delayStep] = [0, 30, 5]; //Sync'ed with fetchDelayUtil
  const [rangeMin, rangeMax, rangeStep] = [20, 1000, 20]; //Sync'ed with fetchRangeUtil

  const handleFetchRangeChange = (value) => {
    setFetchRange(value);
    fetchDelayUtil.set(value);
  };

  const handleFetchDelayChange = (value) => {
    setFetchDelay(value);
    fetchDelayUtil.set(value);
  };
  const handleAllowTrackingChange = (value) => {
    allowGeo(value);
    fetchGPSPermissionUtil.set(value);
  };

  // const handleClick = () => {
  //   allowGeo(true);
  //
  //   setIsFullscreen(true);
  // };

  const handleSettingsClose = () => {
    onClose();
  };
  return (
    <Dialog
      onClose={handleSettingsClose}
      aria-labelledby="settings-dialog-title"
      open={open}
    >
      <DialogTitle id="settings-dialog-title" className="dialogTitle">
        Settings
        <IconButton onClick={onClose}>
          <CloseIcon style={{ color: "#ff334b" }} />
        </IconButton>
      </DialogTitle>

      <div className="App-settings-container">
        <article className="content">
          <p>
            Set how frequently the app will fetch new places. Values are in
            seconds.
          </p>
          <div className="slider-legend">
            <span className="slider-car" />
            <span className="slider-bike" />
            <span className="slider-walker" />
          </div>
          <ReactSlider
            className="slider-horizontal"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            value={fetchDelay}
            min={delayMin}
            max={delayMax}
            step={delayStep}
            onChange={handleFetchDelayChange}
            renderThumb={(props) => <div {...props}>{fetchDelay}</div>}
          />
          <p>Set your range. Values are in meters.</p>
          <div className="slider-legend">
            <span className="slider-s_radar" />
            <span className="slider-m_radar" />
            <span className="slider-l_radar" />
          </div>
          <ReactSlider
            className="slider-horizontal"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            value={fetchRange}
            min={rangeMin}
            max={rangeMax}
            step={rangeStep}
            onChange={handleFetchRangeChange}
            renderThumb={(props) => <div {...props}>{fetchRange}</div>}
          />
          <label htmlFor="normal-switch">
            <span>Enable Location </span>
            <MaterialSwitch
              className="react-switch"
              checked={fetchGPSPermissionUtil.get()}
              onChange={handleAllowTrackingChange}
              label="Share Location"
            />
          </label>
        </article>
      </div>
    </Dialog>
  );
};

SettingsView.propTypes = {
  fetchDelay: PropTypes.func,
  setFetchDelay: PropTypes.func,
  fetchRange: PropTypes.number,
  setFetchRange: PropTypes.func,
  allowGeo: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.func,
  setIsFullscreen: PropTypes.func,
};

export default SettingsView;
