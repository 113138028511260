import "./App.css";
import React, { useEffect, useState } from "react";
import getNearby from "./lib/getNearby";
import isDev from "./lib/isDev";
import AR from "./AR";
import { Link } from "react-router-dom";
import fetchDelayUtil from "./_utils/fetchDelayUtil";
import fetchGPSPermissionUtil from "./_utils/fetchGPSPermissionUtil";
import fetchRangeUtil from "./_utils/fetchRangeUtil";
//import InfoView from './InfoView';

const ARView = () => {
  useEffect(() => {
    setViewInfoOpen(false);
    setIsFirstFetch(false);
    const now = Date.now();
    setLastFetch(now);
    getNearby(mapCenter.lat, mapCenter.lng, fetchRange)
      .then((json) => {
        if (json.query && json.query.pages) {
          setPlaces(json.query.pages);
        }
      })
      .catch(logError)
      .finally(() => {
        setFetching(false);
      });
  }, []);

  const delay = fetchDelayUtil.get();
  const range = fetchRangeUtil.get();
  const gpsPermission = fetchGPSPermissionUtil.get();

  const [apiLoaded, setApiLoaded] = useState(false);
  const [fetchDelay, setFetchDelay] = useState(delay); // in seconds
  const [fetchRange, setFetchRange] = useState(range); // in seconds
  const [geo, allowGeo] = useState(false);
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [lastFetch, setLastFetch] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(true);
  const [place, setPlace] = useState(null);
  const [places, setPlaces] = useState([]);
  const [userHasPanned, setUserHasPanned] = useState(true);
  const [viewInfoOpen, setViewInfoOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [mapCenter] = useState({
    lat: 51.5369444,
    lng: -0.17472222222222222,
  });
  const handleTitleClick = () => {
    setViewInfoOpen(false);
    setPlace(null);
  };

  const handleCenterClick = (evt) => {
    evt.preventDefault();
    setUserHasPanned(false);
    setLastFetch(0);
  };

  function logError(err) {
    // only log warnings in development
    if (isDev()) {
      console.warn("ERROR(" + err.code + "): " + err.message);
    }
  }

  const fullscreen = isFullscreen ? "fullscreen" : "";

  const centerOnMeClasses =
    geo && userHasPanned ? "center-on-me active" : "center-on-me";

  //window.addEventListener("deviceorientation", handleOrientation, false);

  return (
    <div className={`App ${fullscreen}`}>
      <header className="App-header">
        <Link className={centerOnMeClasses} to="/" onClick={handleCenterClick}>
          <img src="/img/center-on-me.png" alt="Center on me" />
        </Link>
        <Link className="app-name" to="/" onClick={handleTitleClick}>
          VeraCity.London
        </Link>
        <div id="menu_container">
          <Link className="settings" to="/settings">
            <img src="/img/settings.png" alt="Open settings panel" />
          </Link>
        </div>
      </header>

      <main className="App-map">
        <AR
          apiLoaded={apiLoaded}
          fetchDelay={fetchDelay}
          fetchRange={fetchRange}
          geo={geo}
          isFirstFetch={isFirstFetch}
          lastFetch={lastFetch}
          setApiLoaded={setApiLoaded}
          places={places}
          setIsFirstFetch={setIsFirstFetch}
          setLastFetch={setLastFetch}
          setPlace={setPlace}
          setPlaces={setPlaces}
          setUserHasPanned={setUserHasPanned}
          userHasPanned={userHasPanned}
          setViewInfoOpen={setViewInfoOpen}
        />
      </main>
    </div>
  );
};

export default ARView;
