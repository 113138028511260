import React from "react";
import "./MyLocation.css";

const MyLocation = () => (
  <span className="my-location" role="img" aria-label="My location">
    🔵
  </span>
);

export default MyLocation;
