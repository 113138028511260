// Session and User preferences
import getAPILayers from "./getLayers";
import getWalk from "./getWalk";

export function getSessionPreferences() {
  const SessionPreferences = sessionStorage.getItem("session");
  if (SessionPreferences === undefined) {
    return undefined;
  }
  return JSON.parse(SessionPreferences);
}

export function setLayers() {
  getAPILayers().then((availablesources) => {
    const SessionPreferences = sessionStorage.getItem("session_layers");
    if (SessionPreferences === null) {
      for (let i = 0, l = availablesources.length; i < l; i++) {
        if (availablesources[i].id === 1) {
          availablesources[i].selected = true;
          break;
        }
      }
      sessionStorage.setItem(
        "session_layers",
        JSON.stringify(availablesources),
      );
    } else {
      const sessionPrefAry = JSON.parse(SessionPreferences);
      for (let i = 0, l = sessionPrefAry.length; i < l; i++) {
        for (let j = 0, ll = availablesources.length; j < ll; j++) {
          if (sessionPrefAry[i].name === availablesources[j].name) {
            sessionPrefAry.splice(i, 1, availablesources[j]);
            break;
          }
        }
      }
      sessionStorage.setItem("session_layers", JSON.stringify(sessionPrefAry));
    }
  });
}

export function setSelectedLayer(layerID, selected) {
  const SessionLayers = sessionStorage.getItem("session_layers");
  const SessionPreferences = JSON.parse(SessionLayers);
  for (let j = 0; j < SessionPreferences.length; j++) {
    if (SessionPreferences[j].id === layerID) {
      SessionPreferences[j].selected = selected;
      sessionStorage.setItem(
        "session_layers",
        JSON.stringify(SessionPreferences),
      );
      break;
    }
  }
}

export function GetSelectedLayers() {
  const SessionPreferences = sessionStorage.getItem("session_layers");
  if (SessionPreferences == null) return null;
  const sp = JSON.parse(SessionPreferences);
  const x = [];
  for (let i = 0; i < sp.length; i++) {
    if (sp[i].selected) {
      x.push(sp[i].id);
    }
  }
  return x;
}

export function getLayers() {
  const SessionPreferences = sessionStorage.getItem("session_layers");
  if (SessionPreferences === null) {
    return [];
  }
  return JSON.parse(SessionPreferences);
}

export async function setSelectedWalkPrf(walkId) {
  sessionStorage.setItem("session_walkstage", 1);
  await getWalk(walkId).then((walkDetails) => {
    sessionStorage.setItem("session_walk_details", JSON.stringify(walkDetails));
    //console.log("setItem session_walk_details")
    // var len = (walkDetails?.points).length;
    // sessionStorage.setItem('session_walk_length', JSON.stringify(len))
  });
}

export function getStageCoords() {
  const walk = sessionStorage.getItem("session_walk_details");
  const walkstage = sessionStorage.getItem("session_walkstage");
  const walkObj = JSON.parse(walk);
  let nWalkstage = parseInt(walkstage);
  if (nWalkstage > walkObj.points.length) {
    nWalkstage = walkObj.points.length;
  }
  if (nWalkstage < 0) {
    nWalkstage = 0;
  }

  if (walkObj.points[nWalkstage]) {
    return {
      lat: parseFloat(walkObj.points[nWalkstage]?.lat),
      lng: parseFloat(walkObj.points[nWalkstage]?.lon),
    };
  } else {
    return {
      lat: parseFloat(walkObj.points[0]?.lat),
      lng: parseFloat(walkObj.points[0]?.lon),
    };
  }
}

export function getSelectedWalk() {
  const walk = sessionStorage.getItem("session_walk_details");
  if (walk === null) return [];
  return JSON.parse(walk);
}

export function isWalkSelected() {
  const walk = sessionStorage.getItem("session_walk_details");
  if (walk === null) return false;
  return true;
}

export function CloseWalkPrf() {
  sessionStorage.removeItem("session_walkstage");
  sessionStorage.removeItem("session_walk_details");
}

export function getWalkStage() {
  const walkstage = sessionStorage.getItem("session_walkstage");
  if (walkstage === null) return 0;
  return walkstage;
}

export function getWalkStages() {
  const walkstages = sessionStorage.getItem("session_walk_details");
  if (walkstages === null) return 0;
  return walkstages;
}

export function incrementWalkStage() {
  const walkstage = sessionStorage.getItem("session_walkstage");
  if (walkstage === null) return 0;
  const maxStage = parseInt(sessionStorage.getItem("session_walk_length"));
  let ws = parseInt(walkstage);
  if (ws === maxStage) return -1;
  ws++;
  sessionStorage.setItem("session_walkstage", ws);
  return ws;
}

export function decrementWalkStage() {
  const walkstage = sessionStorage.getItem("session_walkstage");
  if (walkstage === null) return 0;
  let ws = parseInt(walkstage);
  if (ws === 1) return -1;
  ws--;
  sessionStorage.setItem("session_walkstage", ws);
  return ws;
}

export function getWalkStageDescription() {
  //currentWalk
  const walkstage = sessionStorage.getItem("session_walkstage");
  if (walkstage === null) {
    return null;
  }
  const nWalksStage = parseInt(walkstage) - 1;
  // const walk = getSelectedWalk();

  //    currentWalk

  const walks = sessionStorage.getItem("session_walk_details");
  const obj = JSON.parse(walks);

  if (obj && obj.points && obj.points[nWalksStage]) {
    return {
      description: obj.points[nWalksStage].description,
      interstitial: obj.points[nWalksStage].interstitial,
    };
  }
  return null;
}
