import { Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";
import React from "react";

const Pin = ({
  title,
  description,
  lat,
  lng,
  thumb,
  place_id,
  source_id,
  canonicalurl,
  setPlace,
  setViewInfoOpen,
  walkStageNumber,
}) => {
  const place = {
    title,
    description,
    thumb,
    place_id,
    source_id,
    canonicalurl,
    lat,
    lng,
    walkStageNumber,
  };
  const icon = {
    url: thumb, // url
    scaledSize: new window.google.maps.Size(50, 50), // scaled size
    origin: new window.google.maps.Point(0, 0), // origin
    anchor: new window.google.maps.Point(0, 0), // anchor
  };

  return (
    <Marker
      {...{
        label: walkStageNumber === 0 ? undefined : walkStageNumber,
        className: "Map-pin",
        onClick: () => {
          setViewInfoOpen(true);
          setPlace(place);
        },
        position: { lat, lng },
        icon,
        title,
      }}
    />
  );
};

Pin.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  lat: PropTypes.any.isRequired,
  lng: PropTypes.any.isRequired,
  thumb: PropTypes.string.isRequired,
  place_id: PropTypes.any.isRequired,
  source_id: PropTypes.any.isRequired,
  canonicalurl: PropTypes.any.isRequired,
  setPlace: PropTypes.func.isRequired,
  setViewInfoOpen: PropTypes.func.isRequired,
  walkStageNumber: PropTypes.any.isRequired,
};

export default Pin;
