import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import GooglePlacesAutoComplete from "./GooglePlacesAC";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

function SearchView(props) {
  const { selected, setSelected, onClose, openSearch, handleSearchClose } =
    props;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="search-dialog-title"
      open={openSearch}
    >
      <DialogTitle id="search-dialog-title" className="dialogTitle">
        Veracity Search
        <IconButton onClick={onClose}>
          <CloseIcon style={{ color: "#ff334b" }} />
        </IconButton>
      </DialogTitle>
      <Box component="span" sx={{ p: 3 }}>
        <GooglePlacesAutoComplete
          selected={selected}
          setSelected={setSelected}
          handleSearchClose={handleSearchClose}
        />
      </Box>
    </Dialog>
  );
}

SearchView.propTypes = {
  onClose: PropTypes.func.isRequired,
  openSearch: PropTypes.bool.isRequired,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  handleSearchClose: PropTypes.func.isRequired,
};
export default SearchView;
