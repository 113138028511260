import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { AddWalk } from "../lib/getWalks";

export default function WalkNewNameDialog(props) {
  const { openWalkName, onClose, setAddWalk, setViewWalksListAdminOpen } =
    props;
  // TODO: Migrate to react-hook-form
  const [Name, setName] = React.useState("");
  const [ShortDesc, setShortDesc] = React.useState("");
  const [LongDesc, setLongDesc] = React.useState("");
  const handleSave = () => {
    const newWalk = {
      id: "",
      name: Name,
      steps: 0,
      owner: "",
      short_description: ShortDesc,
      long_description: LongDesc,
    };
    AddWalk(newWalk);

    setAddWalk(Name);
    onClose();
    setViewWalksListAdminOpen(true);
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog open={openWalkName} onClose={handleClose}>
        <DialogTitle>Walk Name</DialogTitle>
        <DialogContent>
          <DialogContentText>Please describe your new walk.</DialogContentText>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <TextField
                  id="walkname"
                  label="Walk Name"
                  fullWidth
                  variant="standard"
                  defaultValue={Name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm container>
                <TextField
                  id="filled-required"
                  label="Short Description"
                  variant="standard"
                  onChange={(event) => {
                    setShortDesc(event.target.value);
                  }}
                />

                <TextField
                  id="standard-required"
                  label="Long Description"
                  variant="standard"
                  multiline
                  onChange={(event) => {
                    setLongDesc(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

WalkNewNameDialog.propTypes = {
  openWalkName: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setAddWalk: PropTypes.func.isRequired,
  setViewWalksListAdminOpen: PropTypes.func.isRequired,
};
