import React, { useEffect, useState } from "react";
import VPin from "./VPin";
import PropTypes from "prop-types";

const ARPins = ({ places, setPlace, setViewInfoOpen }) => {
  if (!places) return;
  return places.map((place) => {
    const { pageid, coordinates, thumbnail } = place;
    let thumb;
    if (thumbnail && thumbnail.source) {
      thumb = thumbnail.source;
    }
    if (coordinates.length > 0) {
      let { lat, lon } = coordinates[0];
      return (
        <VPin
          key={pageid}
          thumb={thumb}
          lat={lat}
          lng={lon}
          setPlace={setPlace}
          setViewInfoOpen={setViewInfoOpen}
          {...place}
        />
      );
    }
    return null;
  });
};

// TODO: Make this a functional component
function AR({
  places,
  setPlace,
  setViewInfoOpen,
  setIsFirstFetch,
  fetchDelay,
  lastFetch,
  setLastFetch,
  setPlaces,
  userHasPanned,
  setUserHasPanned,
  fetchRange,
}) {
  const [fetching, setFetching] = useState(false); // // set to true when a fetch is in progress
  const [idleEvent, setIdleEvent] = useState(null); // used to cancel `idle` event listener
  const [localGeo] = useState(false);
  const [myCenter] = useState({
    lat: 51.5369444,
    lng: -0.17472222222222222,
  });
  const mapCenter = {
    lat: 51.5369444,
    lng: -0.17472222222222222,
  };
  const [map, setMap] = useState();
  const [timer, setTimer] = useState(null);
  const [newNearbyPlaces, setNewNearbyPlaces] = useState(false);

  const handleIdle = () => {};

  useEffect(() => {
    setIsFirstFetch(false);

    // prevent infowindows from opening
    map.addListener("click", (evt) => {
      evt.stop();
    });

    // if user manually pans the map, stop watching for new locations
    map.addListener("dragstart", () => {
      clearTimeout(timer);
      setUserHasPanned(true);
      setIdleEvent(map.addListener("idle", handleIdle));
    });

    // get first set of places
    setNewNearbyPlaces(true);
  }, [map]);

  /*     getNearby(this.state.mapCenter.lat, this.state.mapCenter.lng, this.state.fetchRange)
  .then(json => {
    if (json.query && json.query.pages) {
      setPlaces(json.query.pages);
    }
  })
  .catch(logError)
  .finally(() => {
    this.setState({
      fetching: false
    });
  }); */

  /*   static getDerivedStateFromProps(prevProps, prevState) {
    console.log("  componentDidUpdate ")
    const { fetching, localGeo, map, maps, panning, userHasPanned, watcher } = prevState;
    const { apiLoaded, geo, isFirstFetch, setUserHasPanned } = prevProps;

    // should only ever fire once, on app load
    if (isFirstFetch) {
      this.makeFirstFetch();
    }

    // start watching for location changes when the user grants
    // location access for the first time
    if (geo && !watcher && !localGeo) {
      this.setState({
        localGeo: true
      });
      setUserHasPanned(false);
      window.requestAnimationFrame(this.watch);
    }

    // this gets hit a lot, but is basically a no-op if a fetch is
    // in progress or pending
    if (localGeo && !userHasPanned && !panning && !fetching) {
      this.panToCenter();
    }
  }; */

  return (
    <a-scene
      vr-mode-ui="enabled: false"
      arjs="sourceType: webcam; videoTexture: true; debugUIEnabled: true"
      renderer="antialias: true; alpha: true"
    >
      <a-camera
        locationfinder
        gps-new-camera="gpsMinDistance: 5; simulateLatitude: 51.515595; simulateLongitude: -0.092255;"
      ></a-camera>
      <ARPins {...{ places, setPlace, setViewInfoOpen }} />
    </a-scene>
  );
}

AR.propTypes = {
  isFirstFetch: PropTypes.any.isRequired,
  setIsFirstFetch: PropTypes.func.isRequired,
  setUserHasPanned: PropTypes.func.isRequired,
  setPlace: PropTypes.func.isRequired,
  setViewInfoOpen: PropTypes.func.isRequired,
  places: PropTypes.any.isRequired,
  apiLoaded: PropTypes.any.isRequired,
  fetchDelay: PropTypes.any.isRequired,
  fetchRange: PropTypes.any.isRequired,
  geo: PropTypes.any.isRequired,
  lastFetch: PropTypes.any.isRequired,
  setApiLoaded: PropTypes.any.isRequired,
  setLastFetch: PropTypes.any.isRequired,
  setPlaces: PropTypes.any.isRequired,
  userHasPanned: PropTypes.any.isRequired,
};

export default AR;
