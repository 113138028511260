import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Popover } from "@material-ui/core";
import PropTypes from "prop-types";
import { getLayers } from "../lib/preferences";
import React from "react";

function LayersView(props) {
  //const [availableWalks, setAvailableWalks] = useState([])
  //const [selectedWalk, setSelectedWalk] = useState([])
  const { anchorEl } = props;
  const { setSelectedLayer } = props;
  const { handleClickAwayFromLayers } = props;
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box sx={{ m: 0.5 }}>
        <FormGroup>
          {getLayers()?.map((source) => (
            <FormControlLabel
              key={source.id}
              control={
                <Checkbox
                  id={source.id}
                  checked={source.selected}
                  onChange={(e) => {
                    setSelectedLayer(parseInt(e.target.id), e.target.checked);
                  }}
                />
              }
              label={source.name}
            />
          ))}
          <Button onClick={() => handleClickAwayFromLayers()}>Close</Button>
        </FormGroup>
      </Box>
    </Popover>
  );
}

LayersView.propTypes = {
  setSelectedLayer: PropTypes.func.isRequired,
  anchorEl: PropTypes.bool.isRequired,
  handleClickAwayFromLayers: PropTypes.object.isRequired,
};
export default LayersView;
