import React from "react";
import { Routes, Route } from "react-router-dom";
import MapView from "./Mapview";
import ARView from "./ARview";
import SplashScreen from "./components/SplashScreen";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  document.addEventListener("keydown", function (event) {
    const key = event.key; // const {key} = event; ES6+
    if (key === "Backspace" || key === "Delete") {
      return false;
    }
  });

  return (
    <Provider store={store}>
      <Routes>
        <Route exact path="/*" element={<SplashScreen />}></Route>
        <Route exact path="/nearby" element={<MapView />}></Route>
        <Route exact path="/ar" element={<ARView />}></Route>
      </Routes>
      <ToastContainer />
    </Provider>
  );
};

export default App;
