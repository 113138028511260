const fetchGPSPermissionUtil = {
  get: () => {
    // get fetchDelay value from localStorage, if one has been saved
    let value = localStorage.getItem("FETCH_GPSPERMISSION");

    // keep these values in sync with Settings

    if (null === value) {
      return false;
    }
    if (value === "true") {
      return true;
    }

    return false;
  },
  set: (value) => {
    localStorage.setItem("FETCH_GPSPERMISSION", value);
  },
};

export default fetchGPSPermissionUtil;
