import { BASE_URL } from "./url";

const getWalk = async (walkId) => {
  let url = `${BASE_URL}/walks/${walkId}`;
  let response = await fetch(url);

  if (response.ok) {
    // HTTP status is 200-299
    let json = await response.json();
    return json;
  } else {
    console.error(`HTTP Error: ${response.status}`);
    return {
      status: 400,
      query: {
        walk: {},
      },
    };
  }
};

export default getWalk;
