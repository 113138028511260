import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { getMyWalks } from "../lib/getWalks";
import makeStyles from "@mui/styles/makeStyles";
import { setTokenFromLogin } from "../lib/authentication";
import { useLoginMutation } from "../redux/services/auth";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,

    "& .MuiTextField-root": {
      margin: 15,
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: 2,
      justifyContent: "center",
    },
  },
}));
const LoginView = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { onClose, open, setViewLoginOpen, setViewRegisterOpen, setMyWalks } =
    props;
  const classes = useStyles();
  const [login] = useLoginMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data } = await login({
      email,
      password,
    });
    if (!data.result) {
      return toast.error(`Unable to login. ${data.message}`);
    }
    console.log("login data:", data);
    setTokenFromLogin(data);
    setViewLoginOpen(false);

    const walks = await getMyWalks();
    console.log("login walks:", walks);
    setMyWalks(walks);
  };

  const handleRegister = () => {
    setViewRegisterOpen(true);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="settings-dialog-title"
      open={open}
    >
      <DialogTitle id="settings-dialog-title" className="dialogTitle">
        Login
        <IconButton onClick={onClose}>
          <CloseIcon style={{ color: "#ff334b" }} />
        </IconButton>
      </DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <Box>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
          />
          <DialogActions>
            <Button onClick={handleSubmit} color="primary">
              Login
            </Button>
            <Button onClick={handleRegister} color="primary">
              Register
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};

LoginView.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  setViewLoginOpen: PropTypes.func.isRequired,
  setViewRegisterOpen: PropTypes.func.isRequired,
  myWalks: PropTypes.any.isRequired,
  setMyWalks: PropTypes.func.isRequired,
};

export default LoginView;
