import { getToken } from "../lib/authentication";
import { BASE_URL } from "./url";

export async function setWalkPoint(walkid, place, description, interstitial) {
  const url = `${BASE_URL}/editors/walks/${walkid}/points`;
  const test = JSON.stringify({
    place_id: place.place_id,
    source_id: place.source_id,
    description: description,
    interstitial: interstitial,
    lat: place.lat,
    lon: place.lng,
  });
  console.log(test);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: getToken() },
    body: JSON.stringify({
      place_id: place.place_id,
      source_id: place.source_id,
      description: description,
      interstitial: interstitial,
      lat: place.lat,
      lon: place.lng,
    }),
  };

  const response = await fetch(url, requestOptions);

  if (response.ok) {
    // HTTP status is 200-299
    let json = await response.json();
    return json;
  } else {
    console.error(`HTTP Error: ${response.status}`);
    return {
      status: 400,
      query: {
        walks: [],
      },
    };
  }
}
