import * as React from "react";

import {
  CloseWalkPrf,
  decrementWalkStage,
  getWalkStage,
  getWalkStageDescription,
  incrementWalkStage,
} from "../../lib/preferences";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { getStageCoords } from "../../lib/preferences";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const WalkInfoDrawer = (props) => {
  const [walkDescription, setWalkDescription] = React.useState(
    getWalkStageDescription()?.description || "",
  );
  const [walkInterstitial, setWalkInterstitial] = React.useState(
    getWalkStageDescription()?.interstitial || "",
  );
  const [walkStage, setWalkStage] = React.useState(getWalkStage());
  const [state, setState] = React.useState({
    bottom: false,
    left: false,
  });
  //const position = props;
  const {
    WalkInfoDrawerOpen,
    setWalkInfoOpen,
    currentWalk,
    handleSearchClose,
  } = props;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const closeWalk = (anchor) => {
    CloseWalkPrf();
    toggleDrawer(anchor, false);
    setWalkInfoOpen(false);
  };
  const OnSkipPrevious = () => {
    if (decrementWalkStage() !== -1) {
      const firstLocation = getStageCoords();
      if (handleSearchClose !== null) {
        handleSearchClose(firstLocation);
      }
    }

    setWalkDescription(getWalkStageDescription()?.description);
    setWalkInterstitial(getWalkStageDescription()?.interstitial);
    setWalkStage(getWalkStage());
  };
  const OnSkipNext = () => {
    if (incrementWalkStage() !== -1) {
      const firstLocation = getStageCoords();
      if (handleSearchClose !== null) {
        handleSearchClose(firstLocation);
      }
    }

    //
    setWalkDescription(getWalkStageDescription()?.description);
    setWalkInterstitial(getWalkStageDescription()?.interstitial);
    setWalkStage(getWalkStage());
  };
  // const OnLoaded = () => {

  // }
  //const data = getWalkStageDescription();
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    buttonDrawer: {
      position: "absolute",
      bottom: 0,
      left: 0,
      border: "1px solid ",
      borderRadius: 0,
    },
  });

  const classes = useStyles();

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{ width: anchor === "left" || anchor === "bottom" ? "auto" : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, textAlign: "center", m: 1 }}
        >
          {currentWalk?.name}
        </Typography>
        <Divider />
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "left", m: 1 }}
        >
          Stage Description: {walkDescription}
        </Typography>
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "left", m: 1 }}
        >
          Next walk description: {walkInterstitial}
        </Typography>
        <Typography
          variant="h7"
          component="div"
          sx={{ flexGrow: 1, textAlign: "left", m: 1 }}
        >
          Step: {walkStage}
        </Typography>
        <Divider />
      </Box>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"bottom"}>
        <SwipeableDrawer
          anchor={"bottom"}
          open={WalkInfoDrawerOpen}
          onClose={toggleDrawer("bottom", false)}
          onOpen={toggleDrawer("bottom", true)}
          setWalkInfoOpen={setWalkInfoOpen}
        >
          <Button fullWidth onClick={toggleDrawer("bottom", false)}>
            <KeyboardArrowDownIcon />
          </Button>

          {list("bottom")}
          <Box component="span" sx={{ p: 4 }}>
            <Button
              disabled={getWalkStageDescription() != null}
              startIcon={<PlayCircleFilledIcon />}
              onClick={() => OnSkipNext()}
            >
              Start Walk
            </Button>

            <Button
              onClick={() => OnSkipPrevious()}
              startIcon={<SkipPreviousIcon />}
            >
              Previous
            </Button>

            <Button onClick={() => OnSkipNext()} startIcon={<SkipNextIcon />}>
              Next
            </Button>

            <Button
              startIcon={<CancelIcon />}
              onClick={() => closeWalk("bottom")}
            >
              End Walk
            </Button>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

WalkInfoDrawer.propTypes = {
  WalkInfoDrawerOpen: PropTypes.bool.isRequired,
  setWalkInfoOpen: PropTypes.func.isRequired,
  currentWalk: PropTypes.any.isRequired,
  handleSearchClose: PropTypes.func.isRequired,
};

export default WalkInfoDrawer;
