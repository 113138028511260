import { toast } from "react-toastify";

export function getToken() {
  const tokenString = sessionStorage.getItem("token");

  const userToken = JSON.parse(tokenString);
  return userToken;
  // Force logged in status
  //return 'lghkghkehgrekjhgk'
}

export function setTokenFromLogin(data) {
  sessionStorage.setItem("token", JSON.stringify(data.token));
  //TODO: Set UID
}

export function logout() {
  sessionStorage.removeItem("token");
  toast.success("You have been logged out.");
  // TODO: This could be removed if session state was in redux
  window.location.reload(false);
}

export function isUserLoggedIn() {
  const tokenString = sessionStorage.getItem("token");
  const decodedJwt = parseJwt(tokenString);
  if (!decodedJwt) return false;
  if (decodedJwt.exp * 1000 < Date.now()) {
    return null;
  }

  const userToken = JSON.parse(tokenString);
  if (userToken) return true;
  return false;
}

export function getUserID() {
  const fakeUID = "48fefe18-17cc-11ee-be56-0242ac120002";
  return fakeUID;
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
