import Pin from "./Pin";
import React from "react";

//const offset = 0.00007;
const offset = 0.00004;
const setItem = ({ lat, lon }) => `${lat.toFixed(5)}-${lon.toFixed(5)}`;

const Pins = ({
  places,
  selectedWalk,
  setPlace,
  setViewInfoOpen,
}) => {
  const uniqueLocations = new Set();

  return places.map(
    ({
      pageid,
      coordinates: [{ lat, lon }],
      thumbnail,
      place_id,
      source_id,
      canonicalurl,
      ...place
    }) => {
      // const walkStageNumber = selectedWalk.reduce(
      //   (count, walk) => (walk.pageid === pageid ? count + 1 : count),
      //   0,
      // );
      const walkStageNumber = 0;
      let finalPosition = { lat, lon };
      if (uniqueLocations.has(setItem(finalPosition))) {
        console.log(
          "Position was the same",
          finalPosition,
          setItem(finalPosition),
        );
        finalPosition = {
          lat: lat + offset,
          lon: lon - offset,
        };
        console.log("New:", finalPosition);
      }
      uniqueLocations.add(setItem(finalPosition));
      // console.log("uniqueLocations:", uniqueLocations);

      return (
        <Pin
          key={pageid}
          thumb={
            thumbnail?.source ? thumbnail.source : "/img/default-thumb.png"
          }
          lat={finalPosition.lat}
          lng={finalPosition.lon}
          setPlace={setPlace}
          setViewInfoOpen={setViewInfoOpen}
          place_id={place_id}
          source_id={source_id}
          canonicalurl={canonicalurl}
          walkStageNumber={walkStageNumber}
          {...place}
        />
      );
    },
  );
};

export default Pins;
