import React from "react";
import PropTypes from "prop-types";

const MapCenter = (props) => (
  <span className={props.classNames} role="img" aria-label="Map center">
    ⨁
  </span>
);

MapCenter.propTypes = {
  classNames: PropTypes.string,
};

export default MapCenter;
