function isDev() {
  return window.location.hostname !== "veracity.london";
}

export const logError = (err) => {
  // only log warnings in development
  if (isDev()) {
    console.error("ERROR(" + err.code + "): " + err.message);
  }
};

export default isDev;
