import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import HikingIcon from "@mui/icons-material/Hiking";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { blue } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import getWalks from "../lib/getWalks";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { setSelectedWalkPrf } from "../lib/preferences";

import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function WalksListView(props) {
  const [availableWalks, setAvailableWalks] = useState([]);
  const [selectedWalk, setSelectedWalk] = useState([]);

  useEffect(() => {
    let ignore = false;
    setAvailableWalks(null);
    getWalks().then((result) => {
      if (!ignore) {
        setAvailableWalks(result);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);

  const classes = useStyles();
  const { onClose, open } = props;
  const handleWalksListClose = (WalkSelected) => {
    if (WalkSelected) {
      setSelectedWalkPrf(WalkSelected).then(() => {
        onClose(WalkSelected);
      });
    }
  };

  const handleListItemClick = (value) => {
    availableWalks?.forEach((walk) => {
      if (walk.id === value) setSelectedWalk(walk);
    });
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title" className="dialogTitle">
        Veracity Walks
        <IconButton onClick={onClose}>
          <CloseIcon style={{ color: "#ff334b" }} />
        </IconButton>
      </DialogTitle>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <List>
              {availableWalks?.map((walk) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(walk.id)}
                  key={walk.id}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <HikingIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={walk.name} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm container>
            <TextField
              id="filled-required"
              label="Walk"
              value={selectedWalk?.short_description}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              id="standard-required"
              label="Description"
              multiline
              value={selectedWalk?.long_description}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              id="standard-read-only-input"
              label="Stops"
              defaultValue="0"
              InputProps={{
                readOnly: true,
              }}
              value={selectedWalk?.steps}
            />
            <div></div>
            <Button onClick={() => handleWalksListClose(selectedWalk?.id)}>
              Choose Walk
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

WalksListView.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedWalk: PropTypes.object.isRequired,
};

export default WalksListView;
