import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../lib/url";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        method: "POST",
        url: "login",
        body: { email, password },
      }),
    }),
    register: builder.mutation({
      query: ({ firstName, lastName, email, password }) => ({
        method: "POST",
        url: "register",
        body: {
          name: `${firstName} ${lastName}`,
          username: email,
          email,
          password,
        },
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation } = authApi;
