import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useRegisterMutation } from "../redux/services/auth";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,

    "& .MuiTextField-root": {
      margin: 10,
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: 15,
    },
  },
}));

const RegisterView = (props) => {
  const classes = useStyles();
  // create state variables for each input
  // TODO: Migrate to react-hook-form
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { open, setViewRegisterOpen } = props;
  const [register] = useRegisterMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data } = await register({
      firstName,
      lastName,
      email,
      password,
    });
    if (!data.result) {
      return toast.error(`Unable to register. ${data.message}`);
    }
    toast.success("Account created! Now you can login.");
    sessionStorage.setItem("token", JSON.stringify(data.token));
    setViewRegisterOpen(false);
  };

  return (
    <Dialog
      onClose={() => setViewRegisterOpen(false)}
      aria-labelledby="settings-dialog-title"
      open={open}
    >
      <DialogTitle id="settings-dialog-title" className="dialogTitle">
        Register
        <IconButton>
          <CloseIcon style={{ color: "#ff334b" }} />
        </IconButton>
      </DialogTitle>
      <Box component="form">
        <form className={classes.root}>
          <TextField
            autoFocus
            label="First Name"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            label="Last Name"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            label="Email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            label="Confirm Password"
            type="password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div>
            <Button onClick={() => setViewRegisterOpen(false)}>Cancel</Button>
            <Button type="submit" color="primary" onClick={handleSubmit}>
              Signup
            </Button>
          </div>
        </form>
      </Box>
    </Dialog>
  );
};

RegisterView.propTypes = {
  open: PropTypes.func,
  setViewRegisterOpen: PropTypes.func,
};

export default RegisterView;
