import "./splash-screen.css";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Navigate } from "react-router-dom";

function SplashScreen() {
  const [redirectToMap, setRedirectToMap] = useState(false);

  useEffect(() => {
    // Automatically redirect after 30 seconds
    if (!redirectToMap) {
      setTimeout(() => setRedirectToMap(true), 30000);
    }
  }, [redirectToMap]);

  if (redirectToMap) {
    return <Navigate replace to={"/nearby"} />;
  }
  return (
    <div className="splash-screen">
      {/* Wait a moment while we load your app. */}
      <div className="header" style={{ margin: "4px 0px 30px 30px" }}>
        <h1>VeraCity</h1>
        <h2>
          Connect To Prosper With The True London <br />
          The 695<sup>th</sup> Lord Mayor’s WebApp Of Walks & Wonders
        </h2>
        <img
          src="/img/Knowledge-Mile-Logo-Colour-300x300.png"
          alt="Knowledge Mile logo"
          width="200"
          height="200"
          onClick={() => setRedirectToMap(true)}
        />{" "}
        <br />
        <p> Click the icon above to enter the application </p>
        {/* <div>
                    This webapp is free but donations to the <Link to="https://www.thelordmayorsappeal.org/get-involved/donate/" className="splashlink" target="_blank" rel="noreferrer"> Lord Mayor’s Appeal </Link> are always welcome.
                    For guided walks in the City and lectures on City-related topics contact the <Link to="https://www.cityoflondonguides.com/" className="splashlink" target="_blank" rel="noreferrer" > Official City Guides </Link>.
                </div> */}
        {/* <div style={{ padding: 2 }}>
                    <Grid container style={{ padding: 45 }} spacing={0.5} direction="row" justifyContent="space-evenly" alignItems="center">
                        <Grid item xs={2}>
                            <img src="/img/Mainelli_Crest.png" alt="Forest" width="81" height="128" />
                        </Grid>
                        <Grid item xs={4} sm >
                            
                        </Grid>
                        <Grid item xs={1} sm >
                            <img src="/img/wcit-logo.png" alt="WCIT" width="128" height="128" />
                        </Grid>
                        <Grid item xs={4} sm >
                        Combining centuries-old tradition with a modern focus, energy and innovation.<br/>
The Worshipful Company of Information Technologists is the 100th livery company of the City of London.<br/>
For over 30 years we have raised money for charity and provided IT skills and training to improve lives across the UK and beyond.
                        </Grid>
                    </Grid>

                </div> */}
        {/* <div>We don't collect personal information.... ***Words Required***</div> */}
        <br />
        <p>Sponsored by</p>
        <div>
          <Grid
            container
            style={{ gap: 5 }}
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Grid item xs={2} sm alignItems="center" justifyContent="center">
              <img
                src="/img/dorant_logo.png"
                alt="Development by Dorant"
                width="120"
              />
            </Grid>
            <Grid item xs={2} sm alignItems="center" justifyContent="center">
              <figure>
                <img src="/img/Mainelli_Crest.png" alt="MRM" width="70" />
                <figcaption>
                  {" "}
                  The 695<sup>th</sup> Lord Mayor of London{" "}
                </figcaption>
              </figure>
            </Grid>
            <Grid item xs={2} sm alignItems="center" justifyContent="center">
              <img src="/img/wcit-logo.png" alt="WCIT" width="90" />
            </Grid>
            <Grid item xs={2} sm alignItems="center" justifyContent="center">
              <img
                src="/img/hyperneph_logo.png"
                alt="Hosting by Hyperneph"
                width="130"
              />
            </Grid>
            <Grid item xs={2} sm alignItems="center" justifyContent="center">
              <img src="/img/zyen_logo.png" alt="Design by Z'Yen" width="80" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default SplashScreen;
