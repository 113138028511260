import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import WalkStageEditDialog from "./walks/WalkStageEdit";
import { isUserLoggedIn } from "./lib/authentication";
import { sentenceCase } from "./lib/text";
import PropTypes from "prop-types";

const InfoView = (props) => {
  const { place, geo, allowGeo, setIsFullscreen, myWalks, onClose, open } =
    props;
  const [editWalkViewShow, setEditWalkViewShow] = React.useState(false);
  // TODO: Migrate to react-hook-form
  const [name, setName] = React.useState("");
  const [walkId, setWalkId] = React.useState(0);
  const handleClick = () => {
    allowGeo(true);
    setIsFullscreen(true);
  };
  const handleInfoClose = () => {
    onClose();
  };
  const handleWalkStageEditClose = () => {
    setEditWalkViewShow(false);
  };
  const handleChange = (e, c) => {
    setName(e.target.value);
    setWalkId(c.props.walkid);

    setEditWalkViewShow(true);

    //WalkStageEditDialog(e.target.value, e.target.key, place)
  };
  const WalkSelector = () => {
    if (isUserLoggedIn()) {
      return (
        <div>
          <FormControl fullWidth>
            <InputLabel id="add-to-walk-select-label">Add to Walk</InputLabel>
            <Select
              labelId="add-to-walk-select-label"
              id="add-to-walk-select"
              label="Add to Walk"
              onChange={handleChange}
            >
              {myWalks?.map((item) => (
                <MenuItem key={item.id} walkid={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <WalkStageEditDialog
            open={editWalkViewShow}
            onClose={handleWalkStageEditClose}
            name={name}
            place={place}
            walkid={walkId}
          />
        </div>
      );
    }
  };
  if (place) {
    let { title, description, thumb, canonicalurl } = place;
    // const wikiLink = `https://www.wikipedia.org/search-redirect.php?search=${encodeURIComponent(
    //   title,
    // )}&family=wikipedia&hiddenLanguageInput=en`;
    return (
      <Dialog
        onClose={handleInfoClose}
        aria-labelledby="settings-dialog-title"
        open={open}
        autoComplete="off"
      >
        <DialogTitle id="settings-dialog-title" className="dialogTitle">
          {sentenceCase(title)}
          <IconButton onClick={onClose}>
            <CloseIcon style={{ color: "#ff334b" }} />
          </IconButton>
        </DialogTitle>

        <div className="App-info-container">
          {thumb ? <img src={thumb} alt={sentenceCase(title)} /> : null}
          {description ? <p>{sentenceCase(description)}</p> : null}
          <p>
            <a target="_blank" rel="noopener noreferrer" href={canonicalurl}>
              See more about this (In New Tab).
            </a>
          </p>
          <WalkSelector myWalks={myWalks} />
        </div>
      </Dialog>
    );
  } else {
    return (
      <div className="App-info-container">
        <h1>
          {geo ? (
            <button disabled>Location shared!</button>
          ) : (
            <button onClick={handleClick}>Share Location</button>
          )}
        </h1>
        <article>
          <p>
            <strong>Click “Share Location”</strong> to discover interesting
            landmarks around you from Veracity.
          </p>
          <p>
            <strong>Tap on a marker</strong> on the map and more information
            about it will show up in this space.
          </p>
        </article>
      </div>
    );
  }
};

InfoView.propTypes = {
  place: PropTypes.any.isRequired,
  geo: PropTypes.any.isRequired,
  allowGeo: PropTypes.any.isRequired,
  setIsFullscreen: PropTypes.func.isRequired,
  onWalk: PropTypes.any.isRequired,
  setOnWalk: PropTypes.func.isRequired,
  myWalks: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.any.isRequired,
};

export default InfoView;
