import React from "react";
import PropTypes from "prop-types";

const VPin = ({
  // title,
  // description,
  // lat,
  // lng,
  thumb,
  // setPlace,
  // setViewInfoOpen,
}) => {
  // const place = {
  //   title,
  //   description,
  //   thumb,
  // };
  if (!thumb) {
    thumb = "/img/default-thumb.png";
  }
  const tlat = 51.5369444;
  const tlng = -0.17472222222222222;
  const ep = `latitude: ${tlat}; longitude: ${tlng}`;
  console.log("EP is " + ep);
  return (
    <a-entity
      material="color: red"
      geometry="primitive: circle"
      gps-new-entity-place={ep}
      scale="10 10 10 "
      look-at="[gps-new-camera]"
    ></a-entity>
  );
};

VPin.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  lat: PropTypes.any.isRequired,
  lng: PropTypes.any.isRequired,
  thumb: PropTypes.any.isRequired,
  setPlace: PropTypes.func.isRequired,
  setViewInfoOpen: PropTypes.func.isRequired,
};

export default VPin;

/**
 *
 *
 *   <div className="VMap-pin">
 <a-entity
 material='color: red'
 geometry='primitive: box'
 gps-new-entity-place={`"latitude: ${lat}; longitude: ${lng}"`}
 scale="10 10 10"></a-entity>


 { onClick={ evt => { setViewInfoOpen(true); setPlace(place) } } }

 </div>

 *
 * */
